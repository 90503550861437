<template>
  <transition v-on:enter="enter" v-on:leave="leave" appear>
    <div class="page absolute top-0 left-0 w-full min-h-screen m-auto bg-red z-50">
      <div class="relative w-full text-right">
        <router-link to="/">
          <CloseButton class="relative inline-block"></CloseButton>
        </router-link>
      </div>
      <div class="relative content mx-auto pt-10 text-center">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import CloseButton from "@/components/closeButton.vue";
import { TweenMax, Power4, Linear } from "gsap";
export default {
  name: "Page",
  components: { CloseButton },
  props:{
    slideSpeed:{
      type:Number,
      default:1.0
    }
  },
  mounted() {
    // window.scrollTo(0, 0);
    // window.addEventListener("scroll", this.lazyLoad);
  },
  methods: {
    enter(el, done) {
      // TweenMax.set("body", { overflow: "hidden" });
      const startPt = window.innerHeight;
      TweenMax.fromTo(
        el,
        { y: startPt },
        {
          y: 0,
          duration: this.slideSpeed,
          delay: 0.25,
          ease: Linear.easeNone,
          onComplete: function() {
            // TweenMax.set("body", { overflow: "inherit" });
            done();
          },
        }
      );
    },
    leave(el, done) {
      // TweenMax.set("body", { overflow: "hidden" });
      const startPt = window.innerHeight;
      TweenMax.fromTo(
        el,
        { y: 0 },
        {
          y: startPt,
          duration: this.slideSpeed,
          delay: 0.25,
          ease: Linear.easeNone,
          onComplete: function() {
            // TweenMax.set("body", { overflow: "inherit" });
            done();
          },
        }
      );
    },
    // lazyLoad: function() {
    // let lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
    // let active = false;
    // if (active === false) {
    //   active = true;
    //   setTimeout(() => {
    //     lazyImages.forEach(function(lazyImage) {
    //       if (
    //         lazyImage.getBoundingClientRect().top <= window.innerHeight &&
    //         lazyImage.getBoundingClientRect().bottom >= 0 &&
    //         getComputedStyle(lazyImage).display !== "none"
    //       ) {
    //         lazyImage.src = lazyImage.dataset.src;
    //         lazyImage.classList.remove("lazy");
    //         lazyImages = lazyImages.filter(function(image) {
    //           return image !== lazyImage;
    //         });

    //         if (lazyImages.length === 0) {
    //           window.removeEventListener("scroll", this.lazyLoad);
    //         }
    //       }
    //     });
    //     active = false;
    //   }, 200);
    // }
    // },
  },
};
</script>
<style lang="scss" scope>
@import "../assets/css/helpers.scss";
h2 {
  font-weight: 900;
  font-size: 1.25em;
}
.bg-red {
  background-color: $red;
}
</style>
